import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import ProLink from '../../shared/ProLink/ProLink';
import TruncateText from '../../shared/TruncateText/TruncateText';
import { dataLayer, customPaging, miniGazelle } from '../../../helpers';

import './NewsLinkBlocks.scss';
import CustomPaging from '../../shared/CustomPaging/CustomPaging';

class NewsLinkBlocks extends React.Component {
    static labels() {
        return ['currentlyOnScreen', 'slide'];
    }

    constructor() {
        super();
        this.state = {};

        this.customEventHandler = this.customEventHandler.bind(this);
    }

    customEventHandler() {
        dataLayer.triggerEvent('slider_arrow_clicked', {
            id: this.props.data.logicalName,
            name: this.props.name,
        });
    }

    render() {
        /**
         * standard props used in the style / function of the module.
         * Null if not specified
         **/

        const logicalName = this.props.data.logicalName || null;

        //const localisationData = this.props.localisationData || {};

        //const locale = localisationData.locale || 'en_GB';

        //const mutatedLocale = MutateLocaleParams(locale);

        /**
         * props specific to this module
         **/

        const newsBlocks = this.props.data.newsBlocks || [];
        const title = this.props.data.title || null;

        /* render slides */

        let slideMarkup;

        if (newsBlocks.length > 0) {
            slideMarkup = newsBlocks.map((slide, i) => {
                /* Content changed as string from prosony will be static and converted.
        const dateString = Date.parse(slide.blocksDate.split(' ')[0]);
        const showDate = slide.showDate;

        const options = {
          weekday: showDate ? 'long' : undefined,
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };

        const date = new Date(dateString);

        const backupDate = `${date.getFullYear.toString()}/${date.getMonth()}`;

       const printDate = date.toLocaleDateString
          ? date.toLocaleDateString(mutatedLocale, options)
          : backupDate;

        console.log('Print date');
        console.log(printDate);
        console.log(mutatedLocale);*/

                return (
                    <div
                        key={i}
                        className={classNames({
                            'product-media-slide': false,
                            'product-media-carousel-has-image': false,
                            newsBlocksSlide: true,
                            container: true,
                        })}
                    >
                        <div className="newsBlocks text-left">
                            <ProLink to={slide.blocksLink} className="block-link">
                                <div className="blocksContent">
                                    <div className="blocksDate">
                                        {slide.blocksDate}
                                        {/*<time dateTime={slide.blocksDate}>{printDate}</time>*/}
                                    </div>
                                    <p>
                                        <TruncateText text={slide.blocksContent} disableHover={true} />
                                    </p>
                                    <p className="readMore">{miniGazelle.label('readMore', true)}</p>
                                </div>
                            </ProLink>
                        </div>
                    </div>
                );
            });
        } else {
            slideMarkup = null;
        }

        /**
         * build the style props
         **/

        const containerClass = classNames({
            'container-fluid': true,
            module: true,
            'newsBlocks-carousel': true,
        });

        /**
         * slider settings
         */

        const newsSliderSettings = {
            arrows: true,
            beforeChange: this.customEventHandler,
            customPaging: CustomPaging,
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            adaptiveHeight: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        adaptiveHeight: true,
                    },
                },
            ],
        };

        return slideMarkup ? (
            <div className={containerClass} id={logicalName}>
                <div className="container">
                    <div className="col-md-12">
                        <h2 className="text-center">{title}</h2>
                    </div>
                </div>
                <div className="container">
                    <Slider {...newsSliderSettings}>{slideMarkup}</Slider>
                </div>
            </div>
        ) : null;
    }
}

export default NewsLinkBlocks;
